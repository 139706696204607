<template>
  <div class="wrapper">
    <div class="page">
      <div id="loader"></div>
      <div class="page-inner" id="main_page">
        <div class="va-card mb-4">
          <div class="dash_card_body pad-lr-0 ov-h">
            <div class="header_section d-block padd-30 pt-0 pb-0">
              <div class="w-100 t-wrap">
                <h4 class="menu_header">New Modifier Group</h4>
              </div>
              <div class="back-btn">
                <router-link :to="{ name: 'modifiers'}"><i class="fa fa-arrow-left" aria-hidden="true"></i>
                </router-link>
                <span>{{ title }}</span>
              </div>
            </div>
            <div class="create_items_section">
              <form @submit.prevent="createModifier">
                <div class="row">
                  <div class="col-md-12 bd-b pad-lr-0">
                    <div class="pl-5 pr-4 col-8">
                       
                      <div class="form-group mb-4">
                        <label>Title</label>
                        <div>
                          <input class="form-control" v-model="title" @blur="v$.title.$touch">
                        </div>
                        <div class="input_error" v-if="v$.title.$error">Title is required.</div>
                   
                      </div>

                      <div class="form-group mb-4 mt-4">
                        <div id="foodie-loading-cover">
                          <div class="row">
                            <div class="col-lg-12">
                              <label>Add Item</label>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-3 col-md-3 col-sm-3">
                              <select class="form-control" @change="getItems()" v-model="typeFilter">
                                <option value="">--Select Item Type--</option>
                                <option :value="true">Sold on its own</option>
                                <option :value="false">Not sold on its own</option>
                              </select>
                            </div>
                            <div class="col-lg-7 col-md-7 col-sm-7">
                              <Multiselect v-model="mditems" mode="tags" placeholder="Add item" track-by="name" label="name" :close-on-select="true" :searchable="true" :options="options">
                                <template v-slot:tag="{ option, handleTagRemove, disabled }">
                                  <div class="multiselect-tag is-user">
                                    {{ option.name }}
                                    <span v-if="!disabled" class="multiselect-tag-remove" @mousedown.prevent="handleTagRemove(option, $event)">
                                      <span class="multiselect-tag-remove-icon"></span>
                                    </span>
                                  </div>
                                </template>
                              </Multiselect>
                            </div>
                            <div class="col-lg-2 col-md-3 col-sm-3">
                              <button type="button" @click.prevent="addItems" :class="['w-100 btn',{'custon_orange':!disableAddBtn},{'btn-dgrey':disableAddBtn}]" :disabled="disableAddBtn">Add</button>
                            </div>
                          </div>
                          <div id="foodie-smaller-loader"> </div>
                        </div>
                      </div>
                    </div>
                    <div class="modifier_items" v-if="modifierItems.length > 0">
                      <div class="form-group">
                        <div class="col-md-8 modifier_items-block">
                          <div class="row mt-3" v-for="(modifieritem, mdindex) in modifierItems" :key="mdindex">
                            <div class="col-md-3">
                              {{mdindex + 1}}. <b>{{modifieritem.item_name}}</b>
                            </div>
                            <div class="col-md-4">
                              <input type="number" class="form-control" :value="modifieritem.price" disabled>
                            </div>
                            <div class="col-md-2">
                              <span @click="removeItem(modifieritem.id)" class="remove_icon glyphicon glyphicon-remove"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

               
                
                
                  <!--  -->
                
               
                  <div class="col-md-12 bd-b pad-lr-0">
                    <div class="pl-5 pr-4 col-8">

                      <div class="rules form-group">
                        <h2 class="section-title">Rules</h2>
                        <p><small>Set rules to control how customers select items in this modifier group</small></p>
                      </div>
                      <div class="form-group">
                        <div class="row">
                          <div class="col-md-8">
                            What’s the Extra Type?
                          </div>
                          <div class="col-md-4">
                            <div class="cond-btn">
                              <label class="radio-inline">
                                <input type="radio" id="vegeterian" v-model="extraType" value="condiment" />
                                Condiment &nbsp;
                              </label>
                              <label class="radio-inline">
                                <input type="radio" id="vegan" v-model="extraType" value="extra" />
                                Extra &nbsp;
                              </label>
                            </div>
                            <div class="input_error" v-if="v$.extraType.$error">Extra type field is required.</div>
                          
                          </div>
                       
                        </div>

                      </div>
                    
                      <div class="form-group">
                        <div class="row">
                          <div class="col-md-8">
                            <span>What’s the minimum number of options a customer must select?</span>
                          </div>
                          <div class="col-md-4">
                            <input type="number" min="0" v-model="minPermitted" @click="customOptionsValidation"  name="min_permitted_unique" class="form-control">
                          </div>
                        </div>

                      </div>
                      <div class="form-group">
                        <div class="row">
                          <div class="col-md-8">
                            What’s the maximum number of options a customer can select?
                          </div>
                          <div class="col-md-4">
                            <input type="number" v-model="maxPermitted" @click="customOptionsValidation"  min="1" class="form-control">
                          </div>
                        </div>

                      </div>
                      <div class="form-group">
                        <div class="row">
                          <div class="col-md-8">
                            <span>How many times can customers select any single option?</span>
                          </div>
                          <div class="col-md-4">
                            <input type="number" min="1" @click="customOptionsValidation" v-model="maxPermittedUnique"  name="min_permitted_unique" class="form-control">
                          </div>
                        </div>

                      </div>
                      <!-- error notifications -->
                      <div class="form-group" v-if="rulesError !==''">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="input_error" >{{rulesError}}</div>
                          </div>
                        </div>
                      </div>
                    <!-- end -->
                    </div>
                  </div>
                  <!-- end edditing -->
               
                  <div class="row bt-b">
                    <div class="col-md-12">
                      <div class="form-group ml-5 mt-3">
                        <button type="submit" class="btn btn-orange mr-3"><span v-if="spinner" v-html="searchspinner"></span> <span v-else>Save</span></button>
                      </div>
                    </div>
                  </div>
                

                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReusableDataMixins from '@/mixins/ReusableDataMixins';
import Multiselect from '@vueform/multiselect';
// import Vue from 'vue'
//  Vue.component('multiselect', Multiselect)
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
    components: {
        Multiselect
    },
    setup() {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            spinner: false,
            searchspinner: "<i class='fa fa-spinner fa-spin '></i> Saving...",
            extraType: null,
            title: '',
            addBtn: 'custom_btn',
            maxPermitted: 1,
            minPermitted: 0,
            maxPermittedUnique: 1,
            disableAddBtn: true,
            mditems: null,
            rulesError: '',
            modifierItems: this.$store.state.modifierItems,
            menuItems: [],
            options: [],
            periodTimeCount: [{ 'key': 1 }],
            coptions: [],
            thirdparties: [],
            typeFilter:''
        }
    },
    watch: {
        mditems() {
            if (this.mditems !== null) {
                this.disableAddBtn = false
                this.addBtn = 'mditem_add_btn'
            } else if (this.mditems === null) {
                this.disableAddBtn = true
                this.addBtn = 'custom_btn'
            }
        },

    },

    validations() {
        return {
            title: { required },
            extraType: { required }
        }
    },
    mounted() {
        if (this.title === '') {
            this.modifierItems = [];
            this.$store.commit('addModifierItems', this.modifierItems);
        }
        this.getItems();
        this.getThirdParties();

    },
    methods: {
        customOptionsValidation(){
          if(this.maxPermittedUnique > this.maxPermitted){
            this.rulesError ='Maximum permitted per item should not be greater than maximum number of modifiers permitted total.';
          }else if(this.minPermitted > this.maxPermitted) {
            this.rulesError ='Maximum should be larger than or equal to the minimum.'
          }else{
            this.rulesError = ''
          }
          return this.rulesError
        },
        async getThirdParties(){
          const config = {
              headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
          };
          const url = this.baseUrl + "/third-party-setting?merchant_id="+this.merchantID;
          this.axios.get(url,config).then((response) => {
            if(response.status === 200){
              const channels = response.data.data
              for(let c = 0; c < channels.length; c++){
                this.coptions.push({
                  "value": channels[c].id,
                  "name": channels[c].channel_name
                })
              }
            }
          });
        },
        async createModifier() {
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            this.spinner = true;
            const isFormCorrect = await this.v$.$validate();
            if (!isFormCorrect) {
                this.spinner = false;
                return;
            }
            if(this.rulesError !=''){
              this.spinner = false;
              return;
            }
            const items = [];
            for (let x = 0; x < this.modifierItems.length; x++) {
                items.push(this.modifierItems[x].id);
            }
            if(this.modifierItems.length == 0){
              toast.error('please add items')
              this.spinner = false;
              return
            }
            const payload = {
                "merchant_id":parseInt(this.merchantID),
                "name": this.title,
                "choices": items,
                "extra_type":this.extraType,
                "max_permitted_unique": this.maxPermittedUnique,
                "max_permitted":this.maxPermitted,
                "min_permitted": this.minPermitted,
                // "merchant_id": 1,
                // "channels": this.thirdparties
            };
            const url = this.baseUrl + "/modifier_group "
            // const jsonParseData = JSON.stringify(payload)
            this.axios.post(url, payload,config).then((response) => {
                    this.spinner = false;
                    if (response.status === 201) {
                        toast.success("Modifier Group created successfully");
                        this.spinner = false;
                        this.$router.push({
                            name: 'modifiers'
                        })
                        // clearing group items
                        this.modifierItems = [];
                        this.$store.commit('addModifierItems', this.modifierItems);
                    }
                })
                .catch((error) => {
                    this.spinner = false;
                    if (error.response.status === 401) {
                            this.$router.push({
                                name: 'login'
                            });
                    }
                })
            return;
        },
        async getItems() {
            this.options = [];
            const LoaderCover = document.getElementById('foodie-loading-cover');
            LoaderCover.style.opacity = .4;
            const loader = document.getElementById('foodie-smaller-loader');
            loader.style.display = 'block';

            let url = `${this.baseUrl}//menu_item`;
            url  = new URL(url);
            url.searchParams.set('merchant_id',this.merchantID);
            url.searchParams.set('standalone',this.typeFilter);
            url.searchParams.set('status',1);
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            this.axios.get(url,config).then((response) => {
                this.menuItems = response.data.data
                for(let x=0 ; x < this.menuItems.length; x++){
                  this.options.push({
                      'value': this.menuItems[x].menu_item,
                      'name': `${this.menuItems[x].menu_item.item_name} - ${this.menuItems[x].metadata.pos_id}`
                  })
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                            this.$router.push({
                                name: 'login'
                            });
                    }
            }).finally(() => {
                loader.style.display = 'none';
                LoaderCover.style.opacity = 1;
              });
        },
        validations() {
            return {
                title: { required },
                extraType: { required }
            }
        },
        removeItem(id) {
            this.modifierItems = this.modifierItems.filter((x) => x.id !== id);
            this.$store.commit('addModifierItems', this.modifierItems);
            return this.modifierItems;
        },
        addItems() {
            if (this.mditems !== null) {
                const itemsArray = [];
                if (this.$store.state.modifierItems.length > 0) {
                    for (let y = 0; y < this.$store.state.modifierItems.length; y++) {
                        itemsArray.push(this.$store.state.modifierItems[y]);
                    }
                    for (let x = 0; x < this.mditems.length; x++) {
                        const i = itemsArray.findIndex(_element => _element.item_name === this.mditems[x].item_name);
                        if (i > -1) {
                            itemsArray[i] = this.mditems[x];
                        } else {
                            itemsArray.push(this.mditems[x]);
                        }
                    }
                    this.$store.commit('addModifierItems', itemsArray);
                } else {
                    this.$store.commit('addModifierItems', this.mditems);
                }
                this.modifierItems = this.$store.state.modifierItems;
            }
            this.mditems = null;
        },
    },
    mixins: [ReusableDataMixins]
}
</script>
